/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.onClientEntry = () => {
  console.log("%c👀", "font-size: x-large;")

  window.addEventListener("load", () => {
    document.body.className = document.body.className.replace(
      /\s*\bno-js\b\s*/,
      ""
    )

    setTimeout(() => {
      document.body.className += " animate"
    }, 800)
  })
}
